// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General layout */
.dashboard {
  padding: 20px;
  color: #fff;
  background-color: #1E1F21;
}

.row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

/* Add additional styling per component as needed */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA,mDAAmD","sourcesContent":["/* General layout */\n.dashboard {\n  padding: 20px;\n  color: #fff;\n  background-color: #1E1F21;\n}\n\n.row {\n  display: flex;\n  justify-content: space-around;\n  margin-bottom: 30px;\n}\n\n/* Add additional styling per component as needed */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
