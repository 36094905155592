import React from 'react';
import TotalVehicles from './components/TotalVehicles';
import KPIBox from './components/KPIBox';
import CCTVStatus from './components/CCTVStatus';
import DeviceStatus from './components/DeviceStatus';
import TrafficTrend from './components/TrafficTrend';
import './App.css';

function App() {
  return (
    <div className="dashboard">
      <div className="row">
        <TotalVehicles />
      </div>
      <div className="row">
        <KPIBox />
      </div>
      <div className="row">
        <CCTVStatus />
        <DeviceStatus />
      </div>
      <div className="row">
        <TrafficTrend />
      </div>
    </div>
  );
}

export default App;
