import React from 'react';
import Chart from 'react-apexcharts';

const CCTVStatus = () => {
  const options = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '16px',
          },
          value: {
            fontSize: '18px',
          },
          total: {
            show: true,
            label: 'Total CCTVs',
            formatter: function () {
              return 5941;
            },
          },
        },
      },
    },
    labels: ['Online', 'Offline', 'N/A'],
  };

  const series = [2430, 1702, 548]; // Online, Offline, N/A

  return (
    <div>
      <h3>Total CCTVs</h3>
      <Chart options={options} series={series} type="radialBar" height={350} />
    </div>
  );
};

export default CCTVStatus;
