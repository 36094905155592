import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrafficLight, faThermometerHalf, faCoins, faSkullCrossbones } from '@fortawesome/free-solid-svg-icons';
import './KPIBox.css';

const KPIBox = () => {
  return (
    <div className="kpi-container">
      <div className="kpi">
        <h2>Traffic Density</h2>
        <h1 style={{ color: '#4CAF50' }}>15%</h1>
        <FontAwesomeIcon icon={faTrafficLight} size="2x" />
      </div>
      <div className="kpi">
        <h2>Temperature</h2>
        <h1 style={{ color: '#FF5722' }}>43.2°C</h1>
        <FontAwesomeIcon icon={faThermometerHalf} size="2x" />
      </div>
      <div className="kpi">
        <h2>Estimated Fine</h2>
        <h1>31,510</h1>
        <FontAwesomeIcon icon={faCoins} size="2x" />
      </div>
      <div className="kpi">
        <h2>Daily Violations</h2>
        <h1 style={{ color: '#FFC107' }}>246</h1>
        <FontAwesomeIcon icon={faSkullCrossbones} size="2x" />
      </div>
    </div>
  );
};

export default KPIBox;
