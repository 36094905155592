// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TotalVehicles.css */
.total-vehicles-container {
  background-color: #2B2C2F;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
}

.total-vehicles h2 {
  font-size: 20px;
  margin: 0;
}

.total-vehicles h1 {
  font-size: 36px;
  margin: 0;
}

.vehicle-categories {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.vehicle-category {
  display: flex;
  align-items: center;
}

.vehicle-category div {
  margin-left: 10px;
}

.vehicle-category h3 {
  margin: 0;
  color: #4CAF50;
}
`, "",{"version":3,"sources":["webpack://./src/components/TotalVehicles.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,cAAc;AAChB","sourcesContent":["/* TotalVehicles.css */\n.total-vehicles-container {\n  background-color: #2B2C2F;\n  padding: 15px;\n  border-radius: 10px;\n  text-align: center;\n  color: #fff;\n}\n\n.total-vehicles h2 {\n  font-size: 20px;\n  margin: 0;\n}\n\n.total-vehicles h1 {\n  font-size: 36px;\n  margin: 0;\n}\n\n.vehicle-categories {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 20px;\n}\n\n.vehicle-category {\n  display: flex;\n  align-items: center;\n}\n\n.vehicle-category div {\n  margin-left: 10px;\n}\n\n.vehicle-category h3 {\n  margin: 0;\n  color: #4CAF50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
