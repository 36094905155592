import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMotorcycle, faCar, faTruck } from '@fortawesome/free-solid-svg-icons';
import './TotalVehicles.css';

const TotalVehicles = () => {
  return (
    <div className="total-vehicles-container">
      <div className="total-vehicles">
        <h2>Total Vehicles</h2>
        <h1>11,302</h1>
      </div>
      <div className="vehicle-categories">
        <div className="vehicle-category">
          <FontAwesomeIcon icon={faMotorcycle} size="2x" />
          <div>
            <p>2-Wheels</p>
            <h3>1,632</h3>
          </div>
        </div>
        <div className="vehicle-category">
          <FontAwesomeIcon icon={faCar} size="2x" />
          <div>
            <p>4-Wheels</p>
            <h3>9,846</h3>
          </div>
        </div>
        <div className="vehicle-category">
          <FontAwesomeIcon icon={faTruck} size="2x" />
          <div>
            <p>Others</p>
            <h3>206</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalVehicles;
