import React from 'react';
import Chart from 'react-apexcharts';

const TrafficTrend = () => {
  const options = {
    chart: {
      stacked: false,
    },
    stroke: {
      width: [0, 4],
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: ['12:00', '12:05', '12:10', '12:15', '12:20'],
    },
    yaxis: [
      {
        seriesName: 'Traffic Volume',
      },
      {
        seriesName: 'Traffic Speed',
        opposite: true,
      },
    ],
  };

  const series = [
    {
      name: 'Traffic Volume',
      type: 'column',
      data: [500, 600, 700, 300, 500],
    },
    {
      name: 'Traffic Speed',
      type: 'line',
      data: [60, 70, 80, 50, 65],
    },
  ];

  return (
    <div>
      <h3>Traffic Trend</h3>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default TrafficTrend;
