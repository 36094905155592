import React from 'react';
import Chart from 'react-apexcharts';

const DeviceStatus = () => {
  const options = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '16px',
          },
          value: {
            fontSize: '18px',
          },
          total: {
            show: true,
            label: 'Total Devices',
            formatter: function () {
              return 1660;
            },
          },
        },
      },
    },
    labels: ['Traffic Light', 'Lamp Post'],
  };

  const series = [830, 830]; // Traffic Light, Lamp Post counts

  return (
    <div>
      <h3>Total Devices</h3>
      <Chart options={options} series={series} type="radialBar" height={350} />
    </div>
  );
};

export default DeviceStatus;
