// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* KPIBox.css */
.kpi-container {
  display: flex;
  justify-content: space-between;
  background-color: #2B2C2F;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
}

.kpi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kpi h1 {
  margin: 0;
  font-size: 36px;
}

.kpi h2 {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/KPIBox.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* KPIBox.css */\n.kpi-container {\n  display: flex;\n  justify-content: space-between;\n  background-color: #2B2C2F;\n  padding: 15px;\n  border-radius: 10px;\n  color: #fff;\n}\n\n.kpi {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.kpi h1 {\n  margin: 0;\n  font-size: 36px;\n}\n\n.kpi h2 {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
